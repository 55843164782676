import React, { Component } from 'react'
import PropTypes from 'prop-types'

import styled, { css } from 'styled-components'
import { modularScale, em } from 'polished'

class FlickityContainer extends Component {
  static propTypes = {
    children: PropTypes.node,
    flickityRef: PropTypes.func,
    minHeight: PropTypes.number,
    onChange: PropTypes.func,
    options: PropTypes.object,
  }

  constructor(props) {
    super(props)

    this._options = {
      contain: true,
      accessibility: false,
      pageDots: true,
      prevNextButtons: false,
      ...props.options,
    }
  }

  _Flickity

  _loadFlickity = () => {
    if (!this._Flickity && typeof window !== 'undefined')
      this._Flickity = require('flickity-fade')
    if (this._Flickity && typeof window !== 'undefined') {
      const { flickityRef } = this.props
      this._flkty = new this._Flickity(this._el, this._options)
      this._flkty.on('change', this._onChange)
      if (flickityRef) flickityRef(this._flkty)
      window.addEventListener('resize', this._resize, false)
    }
  }

  componentDidMount() {
    this._loadFlickity()
  }

  componentWillUnmount() {
    if (this._flkty) {
      window.removeEventListener('resize', this._resize)
      this._flkty.destroy()
      this._flkty = null
    }
  }

  _resize = () => {
    if (this._flkty) this._flkty.resize()
  }

  _onChange = () => {
    const { onChange } = this.props
    if (onChange) onChange(this._flkty.selectedIndex)
  }

  get flickity() {
    return this._flkty
  }

  render() {
    const { minHeight } = this.props
    return (
      <Container ref={i => (this._el = i)} minHeight={minHeight}>
        {this.props.children}
      </Container>
    )
  }
}

export default FlickityContainer

const Container = styled.div`
  position: relative;

  .flickity-page-dots {
    display: flex;
    justify-content: center;
    bottom: 0px;

    .dot {
      width: 20px;
      height: 10px;
      opacity: 1;
      background: transparent;
      border: 2px solid ${({ theme }) => theme.color.grey};
      margin: ${modularScale(-1)} ${modularScale(-4)} 0;
      cursor: pointer;
      transition: background-color 0.4s ease-in, border 0.4s ease-in;

      &:hover {
        background: ${({ theme }) => theme.color.grey};
        border: ${({ theme }) => theme.color.grey};
        transition: background-color0.3s ease-out, border0.3s ease-out;
      }
    }

    .dot.is-selected {
      background: #000;
      border: none;
      cursor: auto;
    }
  }
  ${({ minHeight }) =>
    minHeight &&
    css`
      > div {
        min-height: ${minHeight}px;
      }
    `};
`
