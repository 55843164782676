import React from 'react'

import Button from '../common/Button'

import styled from 'styled-components'
import { modularScale } from 'polished'
import { useInView } from 'react-intersection-observer'
import { Link } from 'gatsby'

const PostLinks = ({ previous, next }) => {
  const [ref, inView] = useInView({ triggerOnce: false, threshold: 0 })
  return (
    <Container ref={ref}>
      {!!previous ? (
        <Link to={`/${previous.slug}/`}>
          <PreviousLink inView={inView}>&#8592; Prev</PreviousLink>
        </Link>
      ) : (
        <div />
      )}
      <Spacer />

      {!!next && (
        <Link to={`/${next.slug}/`}>
          <NextLink inView={inView}>Next &#8594;</NextLink>
        </Link>
      )}
    </Container>
  )
}

export default PostLinks

const Container = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-top: ${modularScale(6)};

  a {
    width: 100%;
  }
`

const PreviousLink = styled(Button)`
  margin-right: auto;
  order: 1;
`

const NextLink = styled(Button)`
  margin-left: auto;
  order: 2;
`

const Spacer = styled.div`
  width: 60px;
`
