import { css } from 'styled-components'

import Bold1 from '../assets/fonts/HelveticaNeueLTW1G-Bd.woff2'
import Bold2 from '../assets/fonts/HelveticaNeueLTW1G-Bd.woff'
import Bold3 from '../assets/fonts/HelveticaNeueLTW1G-Bd.ttf'
import Roman1 from '../assets/fonts/HelveticaNeueLTW1G-Roman.woff2'
import Roman2 from '../assets/fonts/HelveticaNeueLTW1G-Roman.woff'
import Roman3 from '../assets/fonts/HelveticaNeueLTW1G-Roman.ttf'
import Light1 from '../assets/fonts/HelveticaNeueLTW1G-Lt.woff2'
import Light2 from '../assets/fonts/HelveticaNeueLTW1G-Lt.woff'
import Light3 from '../assets/fonts/HelveticaNeueLTW1G-Lt.ttf'

export const fonts = css`
  @font-face {
    font-family: 'HelveticaNeue';
    src: url(${Bold1}) format('woff2'), url(${Bold2}) format('woff'),
      url(${Bold3}) format('truetype');
    font-weight: 700;
    font-style: normal;
  }

  @font-face {
    font-family: 'HelveticaNeue';
    src: url(${Roman1}) format('woff2'), url(${Roman2}) format('woff'),
      url(${Roman3}) format('truetype');
    font-weight: 400;
    font-style: normal;
  }

  @font-face {
    font-family: 'HelveticaNeue';
    src: url(${Light1}) format('woff2'), url(${Light2}) format('woff'),
      url(${Light3}) format('truetype');
    font-weight: 300;
    font-style: normal;
  }
`
