import React from 'react'
import PropTypes from 'prop-types'

import Text from '../common/Text'

import mq from '../../style/mediaQueries'
import styled, { css } from 'styled-components'
import { animated, useSpring } from 'react-spring'
import { useInView } from 'react-intersection-observer'
import { useMedia } from 'use-media'

const config = { mass: 5, tension: 1000, friction: 200 }

const Label = ({ children, delay, color }) => {
  const [ref, inView] = useInView({ triggerOnce: false, threshold: 0.5 })

  const fadeInLabel = useSpring({
    config,
    opacity: inView ? 1 : 0,
    x: inView ? 'translateX(0px)' : 'translateX(-20px)',
    from: { opacity: 0, x: 'translateX(-20px)' },
    delay,
  })

  return (
    <Container ref={ref} style={{ ...fadeInLabel, transform: fadeInLabel.x }}>
      <Text sideways color={color} size="xxs" serif uppercase spacing={3.5}>
        {children}
      </Text>
    </Container>
  )
}

Label.propTypes = {
  children: PropTypes.node,
  color: PropTypes.string,
  delay: PropTypes.number,
}

Label.defaultProps = {
  delay: 100,
}

const VerticalLabel = props => {
  const isWide = useMedia({ minWidth: 769 })
  return isWide ? <Label {...props} /> : null
}

VerticalLabel.propTypes = {
  minWidth: PropTypes.number,
}

export default VerticalLabel

const Container = styled(animated.div)`
  ${({ theme }) => css`
    display: flex;
    justify-content: center;
    align-items: center;

    position: absolute;
    left: 0;
    top: 0;
    width: ${theme.layout.gap};
    height: 100%;

    ${mq.lg`
      display: flex;
    `};
  `};
`
