import React from 'react'
import PropTypes from 'prop-types'

import styled, { css } from 'styled-components'
import { animated } from 'react-spring'

import SVG from 'react-inlinesvg'
import { random } from 'lodash'

const SocialLinks = ({ list, small, style }) => {
  return (
    <List style={style}>
      {!!list &&
        list.map(({ title, image, url }, i) => (
          <SocialItem
            key={i}
            href={`${url}?rand=${random(0, 99999)}`}
            small={small}
            target="_blank"
          >
            <SVG src={image.file.url}>
              <img src={image.file.url} alt={title} />
            </SVG>
          </SocialItem>
        ))}
    </List>
  )
}

SocialLinks.propTypes = {
  list: PropTypes.array,
  small: PropTypes.bool,
  style: PropTypes.object,
}

export default SocialLinks

const hover = ({ theme }) => css`
  svg path {
    transition: all 0.4s ease-in;
  }

  &:hover {
    svg path {
      transition: all 0.3s ease-out !important;
      fill: ${theme.color.red} !important;
    }
  }
`

const List = styled(animated.ul)`
  display: flex;
  list-style: none;
  padding: 0;
  margin: 0;

  a,
  a:active,
  a:visited {
    ${hover};
  }
`

const SocialItem = styled.a`
  ${({ small, theme }) => css`
    padding: 0.3em;
    margin: 0 0.3em;

    cursor: pointer;

    &:first-child {
      padding-left: 0;
      margin-left: 0;
    }

    &:first-last {
      padding-right: 0;
      margin-right: 0;
    }

    svg,
    img {
      height: 100%;
      width: auto;
      min-width: ${small ? 14 : 18}px;
      max-height: ${small ? 14 : 18}px;
      object-fit: contain;
    }

    &:last-child {
      margin-right: 0;
      padding-right: 0;
    }
  `};
`
