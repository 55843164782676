import React, { Component } from 'react'
import PropTypes from 'prop-types'

import Text from './Text'

import styled from 'styled-components'

class ErrorBoundary extends Component {
  static propTypes = {
    children: PropTypes.any,
    fill: PropTypes.bool,
    label: PropTypes.string,
    test: PropTypes.bool,
    slient: PropTypes.bool,
  }

  state = {
    hasError: false,
  }

  componentDidCatch(error, info) {
    const { label } = this.props
    this.setState({ hasError: true })

    console.warn('Error:', label, error, info)
  }

  render() {
    const { children, fill, label, slient, test } = this.props
    return !slient && (test || this.state.hasError) ? (
      <ErrorDisplay directionColumn fill={fill}>
        <Text.h2 center serif uppercase spacing={4}>
          Something went wrong.
        </Text.h2>

        {!!label && (
          <Text.p center color="red" sm="sm" size="md">
            {label}
          </Text.p>
        )}
      </ErrorDisplay>
    ) : (
      children
    )
  }
}

export default ErrorBoundary

const ErrorDisplay = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: ${({ fill }) => (fill ? '100vw' : '100%')};
  height: 100%;
  min-height: 300px;
  text-align: center;

  h2 {
    margin: 0.5em 0;
  }
`
