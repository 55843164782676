import React from 'react'
import PropTypes from 'prop-types'

import styled from 'styled-components'
import { get } from 'lodash'

const StaticLogo = props => (
  <Svg viewBox="0 0 358.56 343.66" {...props}>
    <path
      fill="#fff"
      d="M0 .15v254.28h88.8v89l269.76.23V0zm137.8 205.64v-68.28h83.13v68.7zm-49-117.28v116.92H49V50l260.56-.84v245.46l-171.76-.15v-39.68l132.13.67V88.51z"
    />
  </Svg>
)

export default StaticLogo

StaticLogo.propTypes = {
  color: PropTypes.string,
}

StaticLogo.defaultProps = {
  color: 'red',
}

const Svg = styled.svg`
  path {
    fill: ${({ color, theme }) => get(theme.color, color) || color};
  }
`
