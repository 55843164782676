import React from 'react'
import PropTypes from 'prop-types'

import SocialLinks from '../common/SocialLinks'
import Text from '../common/Text'

import scrollTo from '../../utils/scrollTo'
import { animated, useTrail, useSpring } from 'react-spring'
import styled from 'styled-components'
import { modularScale } from 'polished'

const config = { mass: 5, tension: 2000, friction: 200 }
const delay = 200

const Menu = ({ isIndex, getLink, navLinks, socialLinks, toggle }) => {
  const trail = useTrail(navLinks.length, {
    config,
    opacity: 1,
    transform: 'translateY(0px)',
    from: { opacity: 0, transform: 'translateY(-10px)' },
    delay,
  })

  const fadeInText = useSpring({
    config,
    opacity: 1,
    transform: 'translateY(0px)',
    from: { opacity: 0, transform: 'translateY(-10px' },
    delay: 500,
  })

  return (
    <Container>
      <ul>
        {!!trail &&
          trail.map(({ x, ...rest }, index) => {
            const { title, slug } = navLinks[index]
            const item =
              isIndex && slug !== 'store' ? (
                <div
                  onClick={() => {
                    toggle()
                    scrollTo(slug, false)
                  }}
                >
                  {title}
                </div>
              ) : (
                getLink(slug, title, isIndex)
              )
            return (
              <animated.div key={title} style={{ ...rest }}>
                <NavItem
                  bold
                  size="xs"
                  spacing={2}
                  color="white"
                  hoverColor="grey"
                >
                  {item}
                </NavItem>
              </animated.div>
            )
          })}
      </ul>
      <animated.div style={{ ...fadeInText, transform: fadeInText.transform }}>
        {!!socialLinks && <SocialLinks list={socialLinks} />}
      </animated.div>
    </Container>
  )
}

Menu.propTypes = {
  isIndex: PropTypes.bool,
  getLink: PropTypes.func,
  navLinks: PropTypes.array,
  socialLinks: PropTypes.array,
  toggle: PropTypes.func,
}

export default Menu

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;

  padding: 0 ${modularScale(3)};

  > ul:first-child {
    margin: ${modularScale(4)} 0;
  }
`

const NavItem = styled(Text.li)`
  ${({ theme }) => theme.mixin.hover};

  font-size: 10vw;
  line-height: 1.5;
  transition: color 0.4s ease-in, opacity 0.4s ease-in;
  z-index: 9999;
  text-align: right;

  a {
    color: currentColor;
  }

  &:hover {
    color: white;
    transition: color 0.3s ease-out, opacity 0.3s ease-out;
  }
`
