import React from 'react'

import styled from 'styled-components'
import { modularScale } from 'polished'
import mq from '../../style/mediaQueries'

const MenuButton = props => (
  <Button>
    <Svg
      width={30}
      height={30}
      viewBox="0 0 24 24"
      fill="none"
      stroke="#fff"
      strokeWidth={2}
      strokeLinejoin="bevel"
      {...props}
    >
      <path d="M3 12h18M3 6h18M3 18h18" />
    </Svg>
  </Button>
)

export default MenuButton

const Button = styled.button`
  display: flex;
  align-items: center;
  padding: 0;
  margin: 0;
  margin-left: ${modularScale(3)};
  background: transparent;
  cursor: pointer;

  ${mq.lg`
    display: none;
  `};
`

const Svg = styled.svg`
  height: 100%;
  width: auto;
`
