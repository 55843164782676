import React, { useEffect, useState } from 'react'

import ErrorBoundary from './common/ErrorBoundary'
import Helmet from 'react-helmet'
import GlobalStyle from '../style/global'
import Loader from './common/Loader'
import SEO from './SEO'

import { ThemeProvider } from 'styled-components'

import config from '../utils/siteConfig'
import theme from '../style/theme'
import styled from 'styled-components'

import favicon from '../images/favicon.ico'

const loadIO = async () => await require('intersection-observer')
let loadedIO = false

const Layout = ({ post, site, children }) => {
  const needsIO =
    typeof window !== 'undefined' &&
    typeof window.IntersectionObserver === 'undefined'
  const [show, setShow] = useState(needsIO ? false : true)

  useEffect(() => {
    if (needsIO && !loadedIO) {
      loadIO()
      loadedIO = true
      setShow(true)
    }
  })

  return (
    <Root>
      <ErrorBoundary slient>
        <Helmet>
          <title>{config.siteTitle}</title>
          <meta charSet="utf-8" />
          <meta name="viewport" content="width=device-width, initial-scale=1" />
          <link rel="icon" href={favicon} />
        </Helmet>
      </ErrorBoundary>

      <SEO {...site} {...post} />

      <ThemeProvider theme={theme}>
        {show ? (
          <Content id="layout">{children}</Content>
        ) : (
          <Center>
            <Loader />
          </Center>
        )}
      </ThemeProvider>
      <GlobalStyle />
    </Root>
  )
}

export default Layout

const Root = styled.div`
  display: flex;
  flex-direction: column;

  height: 100vh;
  max-width: 100vw;

  overflow-x: hidden;
`

const Content = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1 0 auto;
`

const Center = styled.div`
  display: flex;
  flex-direction: center;
  align-items: center;
  width: 100%;
  height: 100%;
`
