const scrollTo = (slug, smooth = true) => {
  if (typeof document !== 'undefined') {
    const el = document.getElementById(slug)
    if (el) {
      el.scrollIntoView({ behavior: smooth ? 'smooth' : 'auto' })
    }
  }
}

export default scrollTo
