import React, { Fragment } from 'react'
import PropTypes from 'prop-types'

import ErrorBoundary from '../common/ErrorBoundary'
import FlickityContainer from '../common/Flickity'
import Img from 'gatsby-image'
import Text from '../common/Text'

import { color } from '../../style/theme'
import { animated, useSpring } from 'react-spring'
import styled, { css } from 'styled-components'
import { modularScale } from 'polished'
import { useInView } from 'react-intersection-observer'

const config = { mass: 5, tension: 1000, friction: 200 }

const Slideshow = ({ delay, id, images, title }) => {
  const [ref, inView] = useInView({ triggerOnce: false, threshold: 0 })

  const fadeInText = useSpring({
    config,
    opacity: inView ? 1 : 0,
    y: inView ? 'translateY(0px)' : 'translateY(-20px)',
    from: { opacity: 0, y: 'translateY(-20px)' },
    delay,
  })

  const fadeInImages = useSpring({
    config,
    opacity: inView ? 1 : 0,
    y: inView ? 'translateY(0px)' : 'translateY(-20px)',
    from: { opacity: 0, y: 'translateY(-20px)' },
    delay: delay + (!!title ? 100 : 0),
  })

  const Wrapper = images && images.length > 1 ? FlickityContainer : Fragment

  return (
    <ErrorBoundary label="Slideshow">
      <Container key={id} ref={ref}>
        {!!title && (
          <animated.div style={{ ...fadeInText, transform: fadeInText.y }}>
            <Text.h2 center>{title}</Text.h2>
          </animated.div>
        )}
        <animated.div style={{ ...fadeInImages, transform: fadeInImages.y }}>
          <Wrapper>
            {images &&
              images.map((item, i) => {
                return (
                  <Image
                    key={`img${i}`}
                    i={i}
                    fluid={item.fluid}
                    backgroundColor={color.imageBg}
                    objectFit="contain"
                    objectPosition="50% 50%"
                  />
                )
              })}
          </Wrapper>
        </animated.div>
      </Container>
    </ErrorBoundary>
  )
}

Slideshow.propTypes = {
  delay: PropTypes.number,
  id: PropTypes.string,
  images: PropTypes.array,
  title: PropTypes.string,
}

export default Slideshow

const Container = styled.div`
  ${({ theme }) => css`
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    margin-bottom: ${modularScale(6)};

    max-width: ${theme.layout.maxWidth};
    overflow-x: hidden;

    h1,
    h2,
    h3,
    p {
      margin-bottom: ${modularScale(3)};
      padding: 0 4vw;
    }
  `}
`

const Image = styled(Img)`
  width: 100%;
  max-height: 45vw;
  max-height: 780px;
`
