import React from 'react'
import { PropTypes } from 'prop-types'

import Helmet from 'react-helmet'
import Layout from '../components/Layout'
import Nav from '../components/nav/Nav'
import TagList from '../components/blog/TagList'
import PostLinks from '../components/blog/PostLinks'
import SEO from '../components/SEO'
import WorkDetail from '../components/work/WorkDetail'

import config from '../utils/siteConfig'
import { get } from 'lodash'
import { graphql } from 'gatsby'

const ENABLE_TAGS = false

const PostTemplate = ({ data, pageContext, siteName }) => {
  const { title, slug, tags, ...props } = data.contentfulWorkDetailPage
  const postNode = data.contentfulWorkDetailPage
  const previous = pageContext.prev
  const next = pageContext.next

  const getSectionData = id => {
    return {
      ...get(data, `allContentful${id}.edges[0].node`),
      siteName,
    }
  }

  const site = getSectionData('Site')
  const navLinks = get(site, 'nav')

  const post = {
    pagePath: slug,
    postNode,
    postSEO: true,
  }

  return (
    <Layout site={site} post={post}>
      <Helmet slient>
        <title>{`${config.siteTitle} - ${siteName}`}</title>
      </Helmet>

      <SEO {...site} />

      <Nav {...site} siteName={siteName} navLinks={navLinks} />

      <WorkDetail {...props}>
        {!!ENABLE_TAGS && !!tags && <TagList tags={tags} />}
        <PostLinks previous={previous} next={next} />
      </WorkDetail>
    </Layout>
  )
}

PostTemplate.propTypes = {
  siteName: PropTypes.string,
}

PostTemplate.defaultProps = {
  siteName: config.siteTitle,
}

export const query = graphql`
  query($slug: String!) {
    allContentfulSite {
      edges {
        node {
          siteTitle
          tagLine
          siteDescription {
            childMarkdownRemark {
              rawMarkdownBody
            }
          }
          keywords
          nav {
            id
            title
            slug
          }
          socialLinks {
            id
            title
            url
            username
            image {
              file {
                url
                details {
                  size
                  image {
                    width
                    height
                  }
                }
              }
            }
          }
        }
      }
    }

    contentfulWorkDetailPage(slug: { eq: $slug }) {
      title
      slug
      metaDescription {
        internal {
          content
        }
      }
      publishDate(formatString: "MMMM DD, YYYY")
      publishDateISO: publishDate(formatString: "YYYY-MM-DD")
      tags {
        title
        id
        slug
      }
      heroImage {
        title
        fluid(maxWidth: 1800) {
          ...GatsbyContentfulFluid_withWebp_noBase64
        }
        ogimg: resize(width: 1800) {
          src
          width
          height
        }
      }
      heading {
        childMarkdownRemark {
          html
        }
      }
      body {
        childMarkdownRemark {
          html
          excerpt(pruneLength: 320)
        }
      }
      links {
        childMarkdownRemark {
          html
        }
      }
      images {
        id
        title
        videoUrl
        images {
          id
          title
          file {
            details {
              image {
                width
                height
              }
            }
          }
          fluid(maxWidth: 1200) {
            ...GatsbyContentfulFluid_withWebp_noBase64
          }
        }
      }
    }
  }
`

export default PostTemplate
