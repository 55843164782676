import PropTypes from 'prop-types'

import styled, { css } from 'styled-components'
import { get } from 'lodash'

const Section = styled.section`
  ${({ color, theme, z }) => css`
    position: relative;
    display: flex;
    justify-content: center;

    width: 100%;

    background-color: ${get(theme.color, color) || color};

    ${z &&
      css`
        z-index: ${z};
      `};
  `};
`

Section.propTypes = {
  color: PropTypes.string,
}

Section.defaultProps = {
  color: '#000',
}

export default Section
