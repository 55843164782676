import React, { useRef } from 'react'
import PropTypes from 'prop-types'

import ErrorBoundary from '../common/ErrorBoundary'
import ReactHtmlParser from 'react-html-parser'
import Section from '../common/Section'
import SectionTitle from '../common/SectionTitle'
import Slideshow from './Slideshow'
import ReactPlayer from 'react-player'
import Text, { setFontSize, sizes } from '../common/Text'
import VerticalLabel from '../common/VerticalLabel'

import { animated, useSpring } from 'react-spring'
import styled, { css } from 'styled-components'
import { modularScale } from 'polished'
import { useInView } from 'react-intersection-observer'
import { get } from 'lodash'
import mq from '../../style/mediaQueries'
import { useSize } from 'react-hook-size'

const config = { mass: 5, tension: 1000, friction: 200 }
let delay = 500
const stagger = 100

const WorkDetail = ({
  backgroundColor,
  body,
  children,
  heading,
  images,
  links,
  siteName,
  title,
}) => {
  const containerRef = useRef()
  const { width } = useSize(containerRef)

  const [ref, inView] = useInView({ triggerOnce: false, threshold: 0 })
  const [linksRef, linksInView] = useInView({
    triggerOnce: false,
    threshold: 0.4,
  })

  const fadeInText = useSpring({
    config,
    opacity: inView ? 1 : 0,
    y: inView ? 'translateY(0px)' : 'translateY(-20px)',
    from: { opacity: 0, y: 'translateY(-20px)' },
    delay: delay,
  })

  const fadeInList = useSpring({
    config,
    opacity: inView ? 1 : 0,
    y: inView ? 'translateY(0px)' : 'translateY(-20px)',
    from: { opacity: 0, y: 'translateY(-20px)' },
    delay: delay + stagger * 4,
  })

  const fadeInLinks = useSpring({
    config,
    opacity: linksInView ? 1 : 0,
    y: linksInView ? 'translateY(0px)' : 'translateY(-20px)',
    from: { opacity: 0, y: 'translateY(-20px)' },
  })

  const headingText = get(heading, 'childMarkdownRemark.html')
  const bodyText = get(body, 'childMarkdownRemark.html')
  const linkText = get(links, 'childMarkdownRemark.html')

  return (
    <ErrorBoundary label="WorkDetail">
      <Section color={backgroundColor || 'offWhite'} ref={ref}>
        <VerticalLabel>{siteName}</VerticalLabel>
        <Content ref={containerRef}>
          <SectionTitle>{title}</SectionTitle>
          <Grid>
            {!!headingText && (
              <Text.animatedDiv
                html
                light
                linkColor="orange"
                style={{ ...fadeInText, transform: fadeInText.y }}
              >
                {ReactHtmlParser(headingText)}
              </Text.animatedDiv>
            )}

            {!!bodyText && (
              <Text.animatedDiv
                html
                light
                linkColor="orange"
                style={{ ...fadeInList, transform: fadeInList.y }}
              >
                {ReactHtmlParser(bodyText)}
              </Text.animatedDiv>
            )}
          </Grid>
          <Slideshows>
            {images &&
              images.length &&
              images.map((slideshow, i) => {
                const images = slideshow.images
                return !!images && !slideshow.videoUrl ? (
                  <Slideshow
                    key={`s${i}`}
                    delay={i === 0 ? 750 : 200}
                    {...slideshow}
                  />
                ) : (
                  <VideoContainer width={width} key={`s${i}`}>
                    <ReactPlayer
                      url={slideshow.videoUrl}
                      height="100%"
                      width="100%"
                      controls
                      key={`s${i}`}
                    />
                  </VideoContainer>
                )
              })}
          </Slideshows>
          <animated.div
            ref={linksRef}
            style={{ ...fadeInLinks, transform: fadeInLinks.y }}
          >
            {!!linkText && (
              <Text.animatedDiv
                center
                html
                light
                linkColor="orange"
                style={{ ...fadeInText, transform: fadeInText.y }}
              >
                {ReactHtmlParser(linkText)}
              </Text.animatedDiv>
            )}
            {children}
          </animated.div>
        </Content>
      </Section>
    </ErrorBoundary>
  )
}

WorkDetail.propTypes = {
  backgroundColor: PropTypes.string,
  body: PropTypes.object,
  children: PropTypes.node,
  images: PropTypes.array,
  title: PropTypes.string,
  siteName: PropTypes.string,
}

export default WorkDetail

const Slideshows = styled.div`
  ${({ theme }) => css`
    ${theme.mixin.breakout};
    display: flex;
    flex-direction: column;
    justify-content: center;

    ${mq.lg`
      ${theme.mixin.clearBreakout};
    `};
  `}
`

const Content = styled.div`
  ${({ theme }) => css`
    width: 100%;
    max-width: ${theme.layout.maxWidth};
    margin-bottom: ${modularScale(4)};
    padding: 0 ${({ theme }) => theme.layout.gapSm};

    ${mq.lg`
      max-width: ${theme.layout.maxWidth};
      margin: ${modularScale(2)} 0;
      padding: 0 ${({ theme }) => theme.layout.gap};
    `};

    h1 {
      ${setFontSize(sizes.lg)};

      ${mq.sm`
        ${setFontSize(sizes.lg2)};
      `};

      ${mq.lg`
        ${setFontSize(sizes.xl)};
      `};
    }

    p {
      ${setFontSize(sizes.sm, 1.6)};

      ${mq.xl`
      ${setFontSize(sizes.md, 1.6)};
    `};
    }
  `}
`

const Grid = styled.div`
  ${({ theme }) => css`
    width: 100%;
    margin-bottom: ${modularScale(4)};

    ${mq.lg`
      display: grid;
      grid-template-columns: 1fr 1.3fr;
    `};

    > div:last-child {
      margin-top: ${modularScale(2)};

      ${mq.lg`
        padding-left: ${modularScale(4)};
        margin-top: 0;
      `};
    }
  `}
`

const VideoContainer = styled.div`
  ${({ width }) => css`
    margin-bottom: ${modularScale(4)};
    width: 100%;
    height: calc(100vw * 9 / 16);

    ${mq.lg`
      width: ${width}px;
      height: calc(${width}px * 9 / 16);
    `};
  `};
`
