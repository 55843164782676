import React from 'react'
import PropTypes from 'prop-types'

import Text from './Text'

import { animated, useSpring } from 'react-spring'
import styled from 'styled-components'
import { useInView } from 'react-intersection-observer'
import { modularScale } from 'polished'

const config = { mass: 5, tension: 1000, friction: 200 }

const SectionTitle = ({ children, delay, ...props }) => {
  const [ref, inView] = useInView({ triggerOnce: false, threshold: 0.2 })

  const fadeInLabel = useSpring({
    config,
    opacity: inView ? 1 : 0,
    x: inView ? 'translateX(0px)' : 'translateX(-20px)',
    from: { opacity: 0, x: 'translateX(-20px)' },
    delay,
  })

  return (
    <StyledText
      size={14}
      serif
      uppercase
      spacing={3.5}
      ref={ref}
      style={{ ...fadeInLabel, transform: fadeInLabel.x }}
      {...props}
    >
      {children}
    </StyledText>
  )
}

SectionTitle.propTypes = {
  children: PropTypes.node,
  delay: PropTypes.number,
  nav: PropTypes.bool,
}

SectionTitle.defaultProps = {
  delay: 400,
}

export default SectionTitle

const StyledText = styled(Text.animatedDiv)`
  margin-bottom: ${({ nav }) => (nav ? 0 : modularScale(4))};
`
