import React, { Fragment, useState, useEffect } from 'react'
import PropTypes from 'prop-types'

import { Link } from 'gatsby'
import Logo from '../common/StaticLogo'
import Menu from '../menu/Menu'
import MenuButton from './MenuButton'
import Modal from '../common/Modal'
import SocialLinks from '../common/SocialLinks'
import Text from '../common/Text'
import { animated, useSpring } from 'react-spring'
import styled, { css } from 'styled-components'
import { modularScale } from 'polished'
import isUrl from 'is-url'
import scrollTo from '../../utils/scrollTo'
import mq from '../../style/mediaQueries'
import { useMedia } from 'use-media'

const TOP = 'top'

const getLink = (url, title, isIndex) => {
  if (isUrl(url)) {
    return (
      <a href={url} target="_blank">
        {title}
      </a>
    )
  }

  return <Link to={isIndex || url === 'store' ? url : `/#${url}`}>{title}</Link>
}

const config = { mass: 5, tension: 1000, friction: 200 }

const Nav = ({ isIndex, navLinks, tagLine, socialLinks }) => {
  const isWide = useMedia({ minWidth: 769 })

  const [show, showModal] = useState(false)
  const toggle = show ? () => showModal(false) : () => showModal(true)
  const fadeIn = useSpring({
    config,
    opacity: 1,
    y: 'translateY(0px)',
    from: { opacity: 0, y: 'translateY(-10px)' },
    delay: 750,
  })

  useEffect(() => {
    if (isWide && show) toggle()
  })

  return (
    <Fragment>
      <Spacer id={TOP} />
      <Header>
        <Container>
          <Link to="/" onClick={isIndex ? scrollTo('TOP') : null}>
            <Left style={{ ...fadeIn, transform: fadeIn.y }}>
              <LogoContainer>
                <Logo />
              </LogoContainer>
              <TagLine size="xxxxs" uppercase color="white" spacing={6}>
                {tagLine}
              </TagLine>
            </Left>
          </Link>

          <Right style={{ ...fadeIn, transform: fadeIn.y }}>
            <ul>
              {!!navLinks &&
                navLinks.map(({ title, slug }) => {
                  const item =
                    !!isIndex && slug !== 'store' ? (
                      <div onClick={() => scrollTo(slug)}>{title}</div>
                    ) : (
                      getLink(slug, title, isIndex)
                    )
                  return (
                    <NavItem
                      key={title}
                      size="xxxs"
                      spacing={2}
                      uppercase
                      color="white"
                      hoverColor="red"
                    >
                      <b>{item}</b>
                    </NavItem>
                  )
                })}
            </ul>
            {!!socialLinks && <SocialLinks list={socialLinks} />}
            <MenuButton onClick={toggle} />
          </Right>
        </Container>
      </Header>
      <Modal
        fullscreen
        show={show}
        hideModal={toggle}
        onClose={() => showModal(false)}
      >
        <Menu
          isIndex={isIndex}
          getLink={getLink}
          navLinks={navLinks}
          socialLinks={socialLinks}
          toggle={toggle}
        />
      </Modal>
    </Fragment>
  )
}

Nav.propTypes = {
  isIndex: PropTypes.bool,
  navLinks: PropTypes.array,
  siteName: PropTypes.string,
  social: PropTypes.array,
}

export default Nav

const height = css`
  ${({ theme }) => css`
    height: ${theme.layout.navMinHeight}px;

    ${mq.lg`
      height: ${theme.layout.navMaxHeight}px;
    `};
  `};
`

const TagLine = styled(Text)`
  display: none;

  ${mq.xl`
      display: block;
    `};
`

const Spacer = styled.div`
  ${height};
  background-color: rgba(0, 0, 0, 1);
`

const Header = styled.header`
  ${height};
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.95);
  z-index: 99999;
`

const Container = styled(animated.nav)`
  ${({ theme }) => css`
    display: grid;
    grid-template-columns: 1fr 1fr;
    justify-content: center;
    align-items: center;
    width: calc(100% - ${theme.layout.padSm});

    ${mq.lg`
      width: calc(100% - ${theme.layout.gap});
    `};

    h1 {
      font-size: 20px;
    }

    ul {
      display: flex;
      list-style: none;
      padding: 0;
      margin: 0;

      &:first-child {
        margin-right: ${modularScale(6)};
      }
    }
  `};
`

const LogoContainer = styled.div`
  display: flex;
  padding-right: ${modularScale(0)};

  a {
    width: 100%;
    height: 100%;
  }

  svg,
  img {
    width: 100%;
    height: auto;
    min-width: 20px;
    max-width: 30px;
    object-fit: contain;

    path {
      fill: #fff;
    }
  }
`

const Left = styled(animated.div)`
  ${({ theme }) => css`
    display: flex;
    align-items: center;
    padding-right: calc(${theme.layout.padSm} / 2);

    ${mq.sm`
      padding-right: ${theme.layout.padSm};
    `};
  `};
`

const Right = styled(animated.div)`
  display: flex;
  justify-content: flex-end;
  align-items: center;

  ul:first-child {
    display: none;

    ${mq.lg`
      display: flex;
    `};
  }
`

const NavItem = styled(Text.li)`
  ${({ theme }) => theme.mixin.hoverColor(theme.color.red)};

  padding: 1em;
  margin: 0 0.3em;
  line-height: 0.1;
  transition: color 0.4s ease-in, opacity 0.4s ease-in;
  z-index: 9999;

  &:first-child {
    margin-left: 0;
    padding-left: 0;
  }

  &:last-child {
    margin-right: 0;
    padding-right: 0;
  }
`
