import { em, stripUnit } from 'polished'
import { css } from 'styled-components'

// em
export const breakpoints = {
  xs: 0, // from 0 to 575
  sm: stripUnit(em(321)), // from 576 to 767
  md: stripUnit(em(577)), // from 576 to 767
  lg: stripUnit(em(769)), // from 768 to 991
  xl: stripUnit(em(993)), // from 992 to 1199
  xxl: stripUnit(em(1200)), // from 1200 to endless
}

// rem
export const fontSizes = {
  xxxxs: 7.5,
  xxxs: 9,
  xxs: 11,
  xs: 13,
  sm: 15,
  md: 18,
  lg: 22,
  lg2: 30,
  xl: 37,
  xxl: 41,
}

export const color = {
  blue: '#2c4545',
  blueAlt: '#0c474f',
  red: '#df5632',
  yellow: '#f4bc50',
  peach: '#e7dcd0',
  grey: '#6f6e70',
  black: '#000',

  // old
  imageBg: '#eeeeee',
  orange: '#ba8940',
  offWhite: '#e1e1e1',
}

const layout = {
  gap: em(100),
  padSm: em(50),
  gapSm: em(25),
  maxWidth: em(1450),
  maxWidthCentered: em(650), // not used,
  navMinHeight: 50, //px
  navMaxHeight: 100, //px
}

const hover = css`
  transition: opacity 0.4s ease-in;
  cursor: pointer;

  &:hover {
    transition: opacity 0.3s ease-out;
    opacity: 0.6;
  }
`

const hoverColor = color => css`
  transition: color 0.4s ease-in;
  cursor: pointer;

  &:hover {
    transition: color 0.3s ease-out;
    color: ${color};
  }
`

const breakout = css`
  width: calc(100% + ${layout.padSm} * 2);
  transform: translateX(-${layout.padSm});
`

const clearBreakout = css`
  width: 100%;
  transform: translateX(0);
`

const theme = {
  breakpoints,
  color,
  fontSizes,
  mixin: {
    breakout,
    clearBreakout,
    hover,
    hoverColor,
  },
  layout,
}

export default theme
