import React, { Component } from 'react'
import PropTypes from 'prop-types'

import CloseIcon from '../../assets/CloseIcon'
import Portal from '../common/Portal'

import keyCodes from '../../constants/keyCodes'
import styled, { css, keyframes } from 'styled-components'
import { timingFunctions, modularScale } from 'polished'
import { isNull } from 'lodash'

class Modal extends Component {
  static propTypes = {
    actions: PropTypes.object,
    children: PropTypes.node,
    fullscreen: PropTypes.bool,
    instagram: PropTypes.bool,
    onClose: PropTypes.func,
    onKeyDown: PropTypes.func,
    hideModal: PropTypes.func.isRequired,
    noScroll: PropTypes.bool,
    show: PropTypes.bool.isRequired,
    small: PropTypes.bool,
    targetId: PropTypes.string,
    title: PropTypes.string,
  }

  state = {
    queuedToHide: false,
  }

  componentDidUpdate(prevProps, prevState) {
    const { show } = this.props
    if (!prevProps.show && show) this._addListeners(show)
  }

  componentWillUnmount() {
    this._addListeners(false)
    this._reset()
  }

  close = () => this._handleClose()

  _addListeners = (add = true) => {
    if (add) window.addEventListener('keydown', this._onKeyDown, false)
    else window.removeEventListener('keydown', this._onKeyDown)
  }

  _onKeyDown = e => {
    const { onKeyDown } = this.props
    const key = e.which || e.keyCode
    if (key === keyCodes.ESC) this._handleClose()
    if (onKeyDown) onKeyDown(key)
  }

  _handleClose = () => {
    const { hideModal, show } = this.props
    if (show) {
      this._reset(true)
      hideModal()

      this._timeout = setTimeout(this._reset, TIME - 10)
    }
  }

  _reset = (queuedToHide = false) => {
    const { onClose } = this.props
    this.setState({ queuedToHide })

    if (!isNull(this._timeout)) {
      clearTimeout(this._timeout)
      this._timeout = null
      if (!queuedToHide && onClose) onClose()
    }
  }

  render() {
    const {
      children,
      fullscreen,
      instagram,
      noScroll,
      show,
      small,
      targetId,
      title,
    } = this.props
    const { queuedToHide } = this.state
    const showAll = show || queuedToHide
    const Wrapper = instagram ? ContentInstagram : Content
    return showAll ? (
      <Portal id={targetId}>
        <Container hide={!show}>
          {!fullscreen && <Bg onClick={this._handleClose} />}
          <Box
            className="modal-box"
            hide={!show}
            fullscreen={fullscreen}
            small={small}
          >
            <Top>
              <CloseButton onClick={this._handleClose}>
                <CloseIcon />
              </CloseButton>
            </Top>
            <Wrapper
              noScroll={noScroll}
              hasTitle={title}
              fullscreen={fullscreen}
            >
              {children}
            </Wrapper>
          </Box>
        </Container>
      </Portal>
    ) : null
  }
}

const TIME = 400

export default Modal

export const fadeIn = keyframes`
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
        animation-delay: 0;
    }
`

export const fadeInWithDelay = keyframes`
    0% {
        opacity: 0;
    }

    10% {
        opacity: 0;
    }

    100% {
        opacity: 1;
        animation-delay: 0;
    }
`

export const fadeAndScaleIn = keyframes`
    0% {
        opacity: 0;
        transform: translateX(-100%);
    }

    100% {
        opacity: 1;
        transform: translateX(0);
        animation-delay: 0;
    }
`

export const fadeOut = keyframes`
    0% {
        opacity: 1;
    }

    100% {
        opacity: 0;
        animation-delay: 0;
    }
`

export const fadeAndScaleOut = keyframes`
    0% {
        opacity: 1;
        transform: translateX(0);
    }

      100% {
        opacity: 0;
        transform: translateX(-100%);
        animation-delay: 0;
    }
`

const transitionIn = css`
  animation: ${fadeIn} ${TIME / 1000}s ${timingFunctions('easeOutQuad')};
`

const transitionOut = css`
  pointer-events: none;
  opacity: 0;
  transition: opacity ${TIME / 1000}s ${timingFunctions('easeInQuad')};
`

const transitionInContent = css`
  animation: ${fadeAndScaleIn} ${TIME / 1000}s ${timingFunctions('easeOutExpo')};
`

const transitionOutContent = css`
  transform: translateX(-100%);
  transition: transform ${TIME / 1000}s ${timingFunctions('easeInExpo')};
`

const Container = styled.div`
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  ${({ hide }) => (hide ? transitionOut : transitionIn)};
  z-index: 99999999999999;
`

const Bg = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  backdrop-filter: blur(2px);
  ${transitionIn};
`

const Box = styled.div`
  position: relative;
  background-color: rgba(0, 0, 0, 1);

  ${({ fullscreen, hide, small }) => css`
    ${fullscreen
      ? css`
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
        `
      : css`
          width: calc(100% - 40px);
          max-width: 800px;
          border-style: solid;
          border-width: 2px;
          border-color: rgb(217, 217, 217);
          box-shadow: 4px 4px 10px 10px rgba(0, 0, 0, 0.1);
        `};

    ${hide ? transitionOutContent : transitionInContent};

    ${!fullscreen &&
      small &&
      css`
        max-width: 500px;
      `};
  `};

  &:after {
    content: '';
    position: absolute;
    left: -2px;
    bottom: -14px;
    width: calc(100% + 4px);
    height: 4px;
    background-color: red;
  }
`

const PAD_V = 50

const Content = styled.div`
  height: calc(100% - ${PAD_V}px);
  max-height: calc(100vh - 120px);
  padding: 0 calc(${({ theme }) => theme.layout.padSm} / 2);

  ${({ fullscreen, hasTitle, noScroll }) => css`
    margin-top: ${hasTitle ? 90 : PAD_V}px;

    ${fullscreen &&
      css`
        height: calc(100vh - ${PAD_V}px);
        max-height: calc(100vh - ${PAD_V}px);
      `};

    ${!noScroll &&
      css`
        overflow-y: scroll;
        -webkit-overflow-scrolling: touch;
      `};
  `};
`

const ContentInstagram = styled.div`
  height: 100%;

  ${({ noScroll }) =>
    !noScroll &&
    css`
      overflow-y: scroll;
      -webkit-overflow-scrolling: touch;
    `};
`

const Top = styled.div`
  position: absolute;
  top: 0;
  padding: 8px 0;
  right: calc(${({ theme }) => theme.layout.padSm} / 2);
  height: ${({ theme }) => theme.layout.navMinHeight}px;
  z-index: 10;
`

const CloseButton = styled.button`
  background: none;
  border: none;
  outline: none;
  margin: 0;
  padding: 2px 5px;
  cursor: pointer;
  transform: translateX(8px);

  svg path {
    transition: fill 0.3s ease-out;
  }

  &:hover {
    svg path {
      fill: red;
      transition: fill 0.3s ease-out;
    }
  }
`

export const ModalPad = styled.div`
  position: relative;

  .plus-button,
  .download-button {
    margin-top: 0;
    margin-right: -10px;
  }

  .k-video {
    .plus-button {
      margin-top: 10px;
      margin-right: 4px;
    }
  }
`
