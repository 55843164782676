import React from 'react'

import { Link } from 'gatsby'
import Text from '../common/Text'

import styled, { css } from 'styled-components'
import { modularScale } from 'polished'

const TagList = props => {
  return (
    <Container>
      <List>
        {props.tags.map(tag => (
          <Tag key={tag.id}>
            <Text nowrap color="currentColor" uppercase size="xs" spacing={2}>
              <b>
                <Link to={`/tag/${tag.slug}/`}>{tag.title}</Link>
              </b>
            </Text>
          </Tag>
        ))}
      </List>
    </Container>
  )
}

export default TagList

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
`

const List = styled.ul`
  display: flex;
  margin: ${modularScale(4)} 0;
`

const Tag = styled.li`
  ${({ theme }) => css`
    margin: 0 0.25em 0.25em 0;

    a {
      padding: 0.5em;
      border: 2px solid ${theme.color.grey};
      cursor: pointer;
      background-color: rgba(0, 0, 0, 0);
      transition: background-color 0.4s ease-in, color 0.4s ease-in,
        border 0.4s ease-in;

      &:hover {
        color: ${theme.color.peach};
        background-color: rgba(0, 0, 0, 1);
        border: 2px solid #000;
        transition: color0.3s ease-out, backgroud-color0.3s ease-out,
          border0.3s ease-out;
        opacity: 1;
      }
    }
  `};
`
