import PropTypes from 'prop-types'

import { SERIF, SANS_SERIF } from '../../style/type'

import { animated } from 'react-spring'
import styled, { css } from 'styled-components'
import { get, isNumber } from 'lodash'
import { rem } from 'polished'
import { fontSizes } from '../../style/theme'
import mq from '../../style/mediaQueries'
import styles from '../../style/theme'

export const sizes = fontSizes
const fontKeys = Object.keys(fontSizes)

export const setFontSize = (size, ratio = 1.3) => css`
  font-size: ${rem(size)};
  line-height: ${rem(size * ratio)};
`

const setSize = (size = 'md', ratio = 1.3) =>
  isNumber(size)
    ? setFontSize(size, ratio)
    : setFontSize(fontSizes[size], ratio)

const Text = styled.div`
  ${({
    bold,
    center,
    color,
    ellipsis,
    yellow,
    html,
    italic,
    light,
    lineHeight,
    lineHeightMultiplier,
    linkColor,
    nowrap,
    right,
    serif,
    selectNone,
    sideways,
    size,
    spacing,
    theme,
    white,
    uppercase,
    xs,
    sm,
    md,
    lg,
    xl,
    xxl,
  }) => css`
    position: relative;

    font-family: ${serif ? SERIF : SANS_SERIF};
    ${setSize(size, lineHeightMultiplier)};
    font-weight: 400;
    margin: 0;

    color: #000;
    text-align: left;
    z-index: 1;

    i {
      font-style: italic;
    }

    b,
    strong {
      font-weight: 700;
    }

    /* Override */

    ${bold &&
      css`
        font-weight: 700;
      `};

    ${center &&
      css`
        text-align: center;
      `};

    ${color &&
      css`
        color: ${get(styles.color, color) || color};
      `};

    ${ellipsis &&
      css`
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      `};

    ${italic &&
      css`
        font-style: italic;
      `};

    ${yellow &&
      css`
        color: ${styles.color.yellow};
      `};

    ${light &&
      css`
        font-weight: 300;

        a,
        a:active,
        a:visited {
          font-weight: 300;
        }
      `};

    ${nowrap &&
      css`
        white-space: nowrap;
      `};

    ${lineHeight &&
      css`
        line-height: ${lineHeight};
      `};

    ${right &&
      css`
        text-align: right;
      `};

    ${selectNone &&
      css`
        user-select: none;
      `};

    ${sideways &&
      css`
        writing-mode: vertical-rl;
        text-orientation: sideways-right;
        text-orientation: sideways;
        transform: scale(-1);
      `}

    ${spacing &&
      css`
        letter-spacing: ${rem(spacing)};
      `};

    ${white &&
      css`
        color: #fff;
      `};

    ${uppercase &&
      css`
        text-transform: uppercase;
      `};

    /* Media queries */

    ${xs &&
      mq.xs`
        ${setSize(xs, lineHeightMultiplier)};
      `};

    ${sm &&
      mq.sm`
        ${setSize(sm, lineHeightMultiplier)};
      `};

    ${md &&
      mq.md`
         ${setSize(md, lineHeightMultiplier)};
      `};

    ${lg &&
      mq.lg`
         ${setSize(lg, lineHeightMultiplier)};
      `};

    ${xl &&
      mq.xl`
         ${setSize(xl, lineHeightMultiplier)};
      `};

    ${xxl &&
      mq.xxl`
         ${setSize(xxl, lineHeightMultiplier)};
      `};

    .yellow {
      color: ${styles.color.yellow};
    }

    a,
    a:active,
    a:visited {
      color: ${linkColor
        ? get(styles.color, linkColor) || styles.color.red
        : 'currentColor'};
      text-decoration: none;
      transition: color 0.4s ease-in;
      font-weight: 700;
    }

    a:hover {
      color: ${styles.color.red};
      transition: opacity 0.3s ease-out;
    }

    img {
      width: 100%;
      height: auto;
    }

    ul {
      list-style: none;
      margin: 0;
      padding: 0;
    }

    li {
      line-height: 1.7;
    }

    ${html &&
      css`
        h1,
        h2,
        h3,
        h4,
        h5,
        h6 {
          font-family: ${SERIF};
          font-weight: normal;
        }

        h1 {
          ${setSize('xl', lineHeightMultiplier)};

          ${mq.md`
            ${setSize('xxl', lineHeightMultiplier)};
          `};
        }

        h2 {
          ${setSize('xl', lineHeightMultiplier)};
        }

        h3 {
          ${setSize('lg', lineHeightMultiplier)};
        }

        p {
          ${setSize('md', 1.3)};
        }
      `};
  `};
`

Text.propTypes = {
  bold: PropTypes.bool,
  center: PropTypes.bool,
  children: PropTypes.node,
  color: PropTypes.string,
  ellipsis: PropTypes.bool,
  yellow: PropTypes.bool,
  html: PropTypes.bool,
  italic: PropTypes.bool,
  light: PropTypes.bool,
  lineHeight: PropTypes.number,
  linkColor: PropTypes.string,
  nowrap: PropTypes.bool,
  right: PropTypes.bool,
  selectNone: PropTypes.bool,
  serif: PropTypes.bool,
  sideways: PropTypes.bool,
  size: PropTypes.oneOfType([PropTypes.number, PropTypes.oneOf(fontKeys)]),
  spacing: PropTypes.number, // rem
  theme: PropTypes.object,
  white: PropTypes.bool,
  uppercase: PropTypes.bool,
  xs: PropTypes.oneOfType([PropTypes.number, PropTypes.oneOf(fontKeys)]),
  sm: PropTypes.oneOfType([PropTypes.number, PropTypes.oneOf(fontKeys)]),
  md: PropTypes.oneOfType([PropTypes.number, PropTypes.oneOf(fontKeys)]),
  lg: PropTypes.oneOfType([PropTypes.number, PropTypes.oneOf(fontKeys)]),
  xl: PropTypes.oneOfType([PropTypes.number, PropTypes.oneOf(fontKeys)]),
  xxl: PropTypes.oneOfType([PropTypes.number, PropTypes.oneOf(fontKeys)]),
}

Text.defaultProps = {
  lineHeightMultiplier: 1.3,
  lineHeight: 1.3,
  size: 'md',
}

Text.h1 = Text.withComponent('h1')
Text.h1.defaultProps = {
  light: true,
  serif: true,
  spacing: 3.5,
  size: 'xl',
}

Text.h2 = Text.withComponent('h2')
Text.h2.defaultProps = {
  light: true,
  italic: true,
  serif: true,
  spacing: 3.5,
  size: 'lg',
}

Text.h3 = Text.withComponent('h3')
Text.h3.defaultProps = {
  light: true,
  italic: true,
  serif: true,
  spacing: 3.5,
  size: 'md',
}

Text.h5 = Text.withComponent('h5')
Text.h6 = Text.withComponent('h6')

Text.p = Text.withComponent('p')
Text.p.defaultProps = {
  light: true,
  lineHeightMultiplier: 1.3,
  lineHeight: 1.3,
  size: 'md',
}

//@NOTE: To be use un place of Text.p, when the CMS returns rich text.
Text.div = Text.withComponent('div')
Text.div.defaultProps = Text.p.defaultProps

Text.span = Text.withComponent('span')

Text.small = Text.withComponent('small')
Text.small.defaultProps = {
  size: 'md',
  light: true,
}

Text.ul = styled(Text.withComponent('ul'))`
  list-style: none;
`
Text.li = Text.withComponent('li')

Text.label = styled(Text.withComponent('label'))`
  display: inline-block;
`
Text.label.defaultProps = {
  size: 'md',
}

Text.fieldError = Text.withComponent('span')
Text.fieldError.defaultProps = {
  uppercase: true,
  medium: true,
  xs: 'xs',
  size: 'sm',
  color: 'red',
}

Text.animated = Text.withComponent(animated.span)
Text.animatedDiv = Text.withComponent(animated.div)

export default Text
