import React, { useRef, useState } from 'react'
import PropTypes from 'prop-types'

import { animated, useChain, useSpring } from 'react-spring'
import Text from './Text'

import styled, { css } from 'styled-components'
import { rem, modularScale } from 'polished'
import * as easings from 'd3-ease'
const shared = {
  config: {
    duration: 1000,
    easing: easings.easeCubicOut,
  },
}

const Button = ({ children, inView, lineColor, nowrap, ...props }) => {
  const topRef = useRef()
  const rightRef = useRef()
  const bottomRef = useRef()
  const leftRef = useRef()

  const top = useSpring({
    ...shared,
    from: { tranform: 'translateX(0%)', opacity: 0 },
    tranform: inView ? 'translateX(0%)' : 'translateX(-100%)',
    opacity: inView ? 1 : 0,
    ref: topRef,
  })

  const right = useSpring({
    ...shared,
    from: { tranform: 'translateY(0%)', opacity: 0 },
    tranform: inView ? 'translateY(0%)' : 'translateY(-100%)',
    opacity: inView ? 1 : 0,
    ref: rightRef,
  })

  const bottom = useSpring({
    ...shared,
    from: { tranform: 'translateX(0%)', opacity: 0 },
    tranform: inView ? 'translateX(0%)' : 'translateX(100%)',
    opacity: inView ? 1 : 0,
    ref: bottomRef,
  })

  const left = useSpring({
    ...shared,
    from: { tranform: 'translateY(0%)', opacity: 0 },
    tranform: inView ? 'translateY(0%)' : 'translateY(100%)',
    opacity: inView ? 1 : 0,
    ref: leftRef,
  })

  const fade = useSpring({
    opacity: inView ? 1 : 0,
    from: { opacity: 0 },
    config: {
      duration: 2000,
      easing: easings.easeQuadOut,
    },
  })

  useChain([topRef, rightRef, bottomRef, leftRef])

  return (
    <StyledLink lineColor={lineColor} {...props}>
      <Shape />
      <Text.animatedDiv
        className="btn-txt"
        nowrap={nowrap}
        color={'currentColor'}
        uppercase
        size="xs"
        spacing={2}
        style={{ opacity: fade.opacity }}
      >
        <b>{children}</b>
      </Text.animatedDiv>
      <Top
        ref={topRef}
        lineColor={lineColor}
        style={{ transform: top.tranform, opacity: top.opacity }}
      />
      <Right
        ref={rightRef}
        lineColor={lineColor}
        style={{ transform: right.tranform, opacity: right.opacity }}
      />
      <Bottom
        ref={bottomRef}
        lineColor={lineColor}
        style={{ transform: bottom.tranform, opacity: bottom.opacity }}
      />
      <Left
        ref={leftRef}
        lineColor={lineColor}
        style={{ transform: left.tranform, opacity: left.opacity }}
      />
    </StyledLink>
  )
}

Button.propTypes = {
  children: PropTypes.node,
  inView: PropTypes.bool,
  nowrap: PropTypes.bool,
  lineColor: PropTypes.string,
}

Button.defaultProps = {
  uppercase: true,
  lineColor: '#000',
}

export default Button

// For hover effect
const Shape = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0);
  transition: opacity 0.4s ease-in, backgroud-color 0.4s ease-in;
  z-index: 1;
`

export const buttonStyle = ({ lineColor, theme: { color } }) => css`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;

  width: 100%;
  height: ${rem(55)};
  max-width: ${rem(255)};
  padding: 0 ${modularScale(1)};

  color: ${lineColor};
  text-decoration: none;

  cursor: pointer;
  transition: background-color 0.4s ease-in, color 0.4s ease-in;

  &:hover {
    opacity: 1;
    color: ${color.peach};
    transition: opacity0.3s ease-out, color0.3s ease-out;

    ${Shape} {
      background-color: rgba(0, 0, 0, 1);
      transition: background-color0.3s ease-out, backgroud-color0.3s ease-out;
    }
  }
`

const line = ({ lineColor }) => css`
  content: '';
  position: absolute;
  background-color: ${lineColor};
`

const Top = styled(animated.div)`
  ${line};
  top: 0;
  left: 0;
  width: 100%;
  height: 2px;
`

const Bottom = styled(animated.div)`
  ${line};
  bottom: 0;
  left: 0;
  width: 100%;
  height: 2px;
`

const Left = styled(animated.div)`
  ${line};
  top: 0;
  left: 0;
  width: 2px;
  height: 100%;
`

const Right = styled(animated.div)`
  ${line};
  top: 0;
  right: 0;
  width: 2px;
  height: 100%;
`

const StyledLink = styled.button`
  ${buttonStyle};
  overflow: hidden;
`
